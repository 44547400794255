<template>
<div class="container-fluid">
  <breadcrumb>
      <img
        src="static/img/survey.svg"
        class="breadcrumb-image"
      />
      <breadcrumb-item>
        <router-link to="/designeffectiveness">Select Entity</router-link>
      </breadcrumb-item>
      <breadcrumb-item>
        <router-link to="..">Design Effectiveness</router-link>
      </breadcrumb-item>
      <breadcrumb-item active>Design Effectiveness Detail</breadcrumb-item>
    </breadcrumb>
  <div class="row">
    <div class="col-md-12">
        <form autocomplete="off">
          <card v-if="loaded">
            <div>
              <div class="row">
                <div class="col-md-6">
                  <fg-input
                    label="Control Framework"
                    name="name"
                    v-model="model.controlFramework"
                    disabled="true"
                  ></fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input label="Date Created / Modified" name="date" v-model="model.date" disabled="true"></fg-input>
                </div>
              </div>
              <fg-input label="Description">
                <textarea
                  name="description"
                  v-model="model.description"
                  disabled="true"
                  class="form-control"
                  style="height: 80px"
                ></textarea>
              </fg-input>
              <div class="text-right">
              <button type="button" @click="close" class="btn btn-fill btn-info btn-wd">
                <span class="btn-label">
                  <i class="fa fa-arrow-circle-left"></i>
                </span>
                Back
              </button>
            </div>
            </div>
          </card>
        </form>

        <card v-if="loaded">
          <div>
            <div class="col-sm-12">
              <el-table v-loading="loading" style="width: 100%;" :data="model.controlFrameworkCategories">
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                ></el-table-column>
                <el-table-column :min-width="130" fixed="right" label="Progress">
                  <template slot-scope="props">
                    <template v-if="props.row.isComplete === 'True'">
                     <l-progress :value="props.row.percentageCompleted" type="success"></l-progress>
                    </template>
                    <template v-else>
                     <l-progress :value="props.row.percentageCompleted" type="info"></l-progress>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column :min-width="120" :width="100" fixed="right" label="Actions">
                  <template slot-scope="props">
                    <a
                      v-tooltip.top-center="'Survey'"
                      class="btn-info btn-simple btn-link"
                      @click="$router.push(`/designeffectiveness/${$route.params.organisationId}/deassessments/${$route.params.id}/detail/${props.row.id}/assessment`)"
                    >
                      <i class="fa fa-tasks"></i>
                    </a>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Tag } from "element-ui";
import { Pagination as LPagination,  Progress as LProgress } from "src/components/index";
import { Breadcrumb, BreadcrumbItem } from "src/components/index";
import $ from "jquery";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LProgress,
    Breadcrumb,
    BreadcrumbItem
  },

  data() {
    return {
      model: {
        id: 0,
        controlFrameworkId: null,
        date: "",
        description: "",
        controlFramework: "",
        isActive: true,
        organisationId: 0,
        controlFrameworkCategories: []
      },
      controlFrameworks: [],
      modelValidations: {
        controlFramework: {
          required: true
        }
      },
      tableColumns: [
        {
          prop: "name",
          label: "Category",
          minWidth: 150
        },
        {
          prop: "score",
          label: "Score",
          minWidth: 80
        },
        {
          prop: "targetScore",
          label: "Target Score",
          minWidth: 80
        },
        {
          prop: "weighting",
          label: "Weighting",
          minWidth: 80
        },
        {
          prop: "user",
          label: "Assigned To",
          minWidth: 150
        }
      ],
      topSelect: false,
      surveys: [],
      users: [],
      userId: "",
      loading: true,
      loaded: false
    };
  },
  created() {
    let self = this;
    self.get(self.$route.params.id);
    self.getAllActiveControlFrameworks();
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$validator.validateAll().then(isValid => {
        this.$emit("on-submit", this.designEffectiveness, isValid);
      });
    },
    getAllActiveControlFrameworks() {
      let self = this;
      self.$store.state.services.controlFrameworkService
        .getAll({
          params: {
            ActiveControlFrameworks: true
          }
        })
        .then(r => {
          self.controlFrameworks = r.data;
        })
        .catch(err => console.log(err));
    },

    ddlControlFrameworkChange(value) {
      let self = this;
      self.model.controlFrameworkId = value;
    },

    getDEControlAssessmentSurveys(value) {
      let self = this;
      self.$store.state.services.dEControlAssessmentSurveyService
        .getAll({
          params: {
            DEControlAssessmentId: value
          }
        })
        .then(r => {
          self.model.controlFrameworkCategories = r.data;
          self.loaded = true;
        })
        .catch(err => console.log(err));
    },

    get(id) {
      let self = this;
      self.$store.state.services.dEControlAssessmentService
        .get(id)
        .then(r => {
          self.model.id = r.data.id;
          self.model.controlFramework = r.data.controlFramework;
          self.model.description = r.data.description;
          self.model.date = r.data.date;
          self.model.controlFrameworkId = r.data.controlFrameworkId;
          self.getDEControlAssessmentSurveys(self.$route.params.id);
          self.loading = false;
        })
        .catch(err => console.log(err));
    },
    close() {
      let self = this;
      self.$router.push(
        "/designeffectiveness/" + self.$route.params.organisationId + "/deassessments"
      );
    },
  }
};
</script>